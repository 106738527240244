import { css } from "@emotion/core"
// import mq from "./mediaQueries"
// import theme from "./theme"
import "./animations.css"

const globalStyles = css`
  /*
      MEYER RESET START
      */
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font-family: Courier, monospace;
    font-size: 14px;
  }
`

export default globalStyles

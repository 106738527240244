import React, { useState } from "react"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"

export default function StripeForm({
  pwSuccess,
  setPaymentSuccess,
  setPaymentError,
}) {
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)
  const [processing, setProcessing] = useState("")
  const [disabled, setDisabled] = useState(true)
  const stripe = useStripe()
  const elements = useElements()

  const { currency, amount } = pwSuccess

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  }

  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty)
    setError(event.error ? event.error.message : "")
  }
  const handleSubmit = async ev => {
    ev.preventDefault()
    setProcessing(true)

    const payload = await stripe.confirmCardPayment(pwSuccess.paymentSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: ev.target.name.value,
        },
      },
    })

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`)
      setProcessing(false)
      setPaymentError(true)
    } else {
      setError(null)
      setProcessing(false)
      setSucceeded(true)
      setPaymentSuccess(true)
    }
  }

  const formatAmount =
    currency && amount
      ? new Intl.NumberFormat("en-US", {
          style: "currency",
          currency,
        })
      : null

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement
        id="card-element"
        options={cardStyle}
        onChange={handleChange}
      />
      {amount ? (
        <button
          disabled={processing || disabled || succeeded}
          id="submit"
          type="submit"
        >
          <span id="button-text">
            {processing ? (
              <div className="spinner" id="spinner" />
            ) : (
              `Pay with Stripe - ${formatAmount.format(amount / 100)}`
            )}
          </span>
        </button>
      ) : null}
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      {/* <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, see the result in your
        <a href="https://dashboard.stripe.com/test/payments">
          {" "}
          Stripe dashboard.
        </a>
      </p> */}
    </form>
  )
}

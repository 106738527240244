import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import axios from "axios"
import { v4 as uuidv4 } from "uuid"

import Layout from "../layout/Layout"
import SEO from "../seo/Seo"
import BlindTiger from "../components/BlindTiger"

export function App() {
  const [guid, setGuid] = useState(null)
  useEffect(() => {
    if (!guid) {
      setGuid(uuidv4())
    }
  })

  const text = useStaticQuery(graphql`
    query SiteTextQuery {
      siteText: allAirtable(filter: { table: { eq: "SiteText" } }) {
        nodes {
          data {
            Key
            Value
          }
          recordId
        }
      }
    }
  `)

  const siteText = text.siteText.nodes.reduce((obj, node) => {
    const { Key, Value } = node.data
    obj[Key] = Value
    return { ...obj }
  }, {})

  const [password, setPassword] = useState("")
  const [pwError, setPwError] = useState(false)
  const [pwSuccess, setPwSuccess] = useState("")
  const [makePayment, setMakePayment] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [paymentError, setPaymentError] = useState(false)

  function handleTextChange(e) {
    setPassword(e.target.value)
  }

  function handleSubmitPassword(e) {
    e.preventDefault()
    axios
      .put(
        "https://d9b3926sx5.execute-api.us-east-1.amazonaws.com/prod/blindtiger/checkpassword",
        { password, guid }
      )
      .then(response => {
        setPwError(false)
        setPwSuccess(response.data)
      })
      .catch(() => {
        setPwSuccess(false)
        setPwError(true)
        setPassword("")
      })
  }

  const [zoomLink, setZoomLink] = useState("")
  const [zoomTries, setZoomTries] = useState(0)
  const [zoomError, setZoomError] = useState(null)
  useEffect(() => {
    if (paymentSuccess && zoomTries < 3) {
      axios
        .put(
          "https://d9b3926sx5.execute-api.us-east-1.amazonaws.com/prod/blindtiger/getLink",
          { guid }
        )
        .then(response => {
          setZoomLink(response.data.link)
        })
        .catch(() => {
          setZoomTries(zoomTries + 1)
        })
    }
    if (zoomTries === 3) {
      setZoomError(true)
    }
  }, [paymentSuccess, zoomTries])

  return (
    <Layout>
      <SEO title="BlindTiger.io" />
      <BlindTiger
        siteText={siteText}
        password={password}
        handleTextChange={handleTextChange}
        handleSubmitPassword={handleSubmitPassword}
        pwSuccess={pwSuccess}
        pwError={pwError}
        setMakePayment={setMakePayment}
        makePayment={makePayment}
        setPaymentSuccess={setPaymentSuccess}
        paymentSuccess={paymentSuccess}
        setPaymentError={setPaymentError}
        paymentError={paymentError}
        zoomLink={zoomLink}
        zoomError={zoomError}
      />
    </Layout>
  )
}

export default App

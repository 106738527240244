import React from "react"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import StripeForm from "./StripeForm"
import "./Stripe.scss"

const stripePromise = loadStripe("pk_test_If555JF7gKdaxwKHqyTYhHF700SumIRjOH")

const Stripe = ({
  setPaymentSuccess,
  setPaymentError,
  siteText,
  pwSuccess,
}) => (
  <div className="Stripe">
    <Elements stripe={stripePromise}>
      <StripeForm
        setPaymentSuccess={setPaymentSuccess}
        setPaymentError={setPaymentError}
        pwSuccess={pwSuccess}
        siteText={siteText}
      />
    </Elements>
  </div>
)
export default Stripe

import React from "react"
import PropTypes from "prop-types"

import { withTheme, ThemeProvider } from "emotion-theming"

import { Global } from "@emotion/core"
import theme from "./theme"

import globalStyles from "./globalStyles"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <>{children}</>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTheme(Layout)

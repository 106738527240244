import React from "react"
import styled from "@emotion/styled"

import Stripe from "./Stripe"

import tiger from "../images/tiger.svg"
import sun from "../images/sun.svg"
import btsymbology from "../images/btsymbology.svg"
import eyes from "../images/eyes.svg"
import hand from "../images/hand.svg"
import tigeryellow from "../images/tigeryellow.svg"

const MainWrapper = styled.main`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 0.3rem;
  @media only screen and (max-width: 1020px) {
    grid-template-rows: repeat(10, 1fr);
    grid-column: 1 / 5;
    grid-row: 1 / 2;
    height: 150vh;
  }
`
const GridBlockOne = styled.div`
  background-color: #16244b;
  width: 100%;
  height: 100%;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  @media only screen and (max-width: 1020px) {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
  }
`
const GridBlockTwo = styled.div`
  background-color: #fa673c;
  width: 100%;
  height: 100%;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  background-image: url(${tiger});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  @media only screen and (max-width: 1020px) {
    grid-column: 1 / 5;
    grid-row: 8 / 9;
  }
`
const GridBlockThree = styled.div`
  /* background-color: #16244b; */
  /* background-color: #643eae; */
  background-color: #16244b;
  width: 100%;
  height: 100%;
  grid-column: 1 / 2;
  grid-row: 3 / 5;
  @media only screen and (max-width: 1020px) {
    grid-column: 1 / 3;
    grid-row: 9 / 11;
  }
`
const GridBlockFour = styled.div`
  background-color: #c8f4fa;
  width: 100%;
  height: 100%;
  grid-column: 2 / 3;
  grid-row: 3 /5;
  background-image: url(${sun});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  @media only screen and (max-width: 1020px) {
    grid-column: 3 / 5;
    grid-row: 9 / 11;
  }
`
const GridBlockFive = styled.div`
  background-color: #6f38b6;
  width: 100%;
  height: 100%;
  grid-column: 3 / 5;
  grid-row: 1 / 3;
  background-image: url(${tigeryellow});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  @media only screen and (max-width: 1020px) {
    grid-column: 1 / 5;
    grid-row: 2 / 5;
  }
`
const GridBlockSix = styled.div`
  background-color: #16244b;
  width: 100%;
  height: 100%;
  grid-column: 3 / 5;
  grid-row: 3 / 5;
  background-image: url(${hand});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  @media only screen and (max-width: 1020px) {
    grid-column: 1 / 5;
    grid-row: 5 / 8;
  }
`
const FormWrapper = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ animationDelay }) => {
    return { animationDelay: `${animationDelay || "0s"}` }
  }};
`
const StandardWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const KnockDoorCodeInput = styled.input`
  border: none;
  background-color: white;
  height: 3rem;
  margin-left: 1rem;
  width: 80%;
  @media only screen and (max-width: 1020px) {
    width: 95%;
  }
  padding-left: 1.5rem;
  :first-of-type {
    margin-left: 0;
  }
`

const LogoWrapper = styled.div`
  margin-left: 5%;
  margin-top: 5%;
`
const Logo = styled.h3`
  text-decoration: none;
  color: white;
  font-size: 2rem;
  letter-spacing: 3px;
  position: absolute;
  top: 10px;
  right: 10px;
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
const Triangle = styled.img`
  width: 80%;
  height: 80%;
  animation: rotation 300s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`
const BTImage = styled.img`
  width: 100%;
  height: 100%;
  padding: 1rem;
`
const DoormanWrapper = styled.div`
  margin: auto;
  width: 80%;
  @media only screen and (max-width: 1020px) {
    width: 95%;
  }
  height: auto;
  display: flex;
  background-color: rgba(255, 255, 255, 0.678);
  ${({ animationDelay, animationDuration }) => {
    return {
      animationDelay: `${animationDelay || "0s"}`,
      animationDuration: `${animationDuration || "1s"}`,
    }
  }};
`
const Doorman = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 20px;
  transition: all 1s;
`
const DoormanChat = styled.div`
  text-align: left;
  ${({ animationDelay, animationDuration }) => {
    return {
      animationDelay: `${animationDelay || "0s"}}`,
      animationDuration: `${animationDuration || "1s"}`,
    }
  }};
  /* ${({ tip }) => {
    return tip && { margin: `20px auto 5px 0` }
  }}; */
`
const TipContainer = styled.div`
  width: 100%;
  display: flex;
  animation-duration: 2s;
  height: 20px;
  ${({ animationDelay }) => {
    return { animationDelay: `${animationDelay || "0s"}}` }
  }};
`
const TipDoorman = styled.button`
  cursor: pointer;
  padding: 2px;
`

const BlindTiger = ({
  siteText,
  password,
  handleTextChange,
  handleSubmitPassword,
  pwSuccess,
  pwError,
  setMakePayment,
  makePayment,
  setPaymentSuccess,
  paymentSuccess,
  setPaymentError,
  zoomLink,
  zoomError,
}) => {
  return (
    <MainWrapper>
      <GridBlockOne>
        <ImageWrapper>
          <BTImage src={eyes} alt="" />
        </ImageWrapper>
      </GridBlockOne>

      <GridBlockTwo />

      <GridBlockThree>
        <ImageWrapper>
          <Triangle src={btsymbology} alt="" />
        </ImageWrapper>
      </GridBlockThree>

      <GridBlockFour />

      <GridBlockFive>
        {(() => {
          if (siteText) {
            if (!pwSuccess) {
              return (
                <FormWrapper
                  onSubmit={handleSubmitPassword}
                  className="text-focus-in"
                  animationDelay="1s"
                >
                  <KnockDoorCodeInput
                    onChange={handleTextChange}
                    placeholder={pwError ? siteText.pwFail : siteText.pw}
                    value={password}
                  />
                </FormWrapper>
              )
            }

            if (pwSuccess && !makePayment) {
              return (
                <DoormanWrapper className="text-focus-in" animationDelay="1s">
                  <FormWrapper
                    onSubmit={e => {
                      e.preventDefault()
                      if (pwSuccess.amount) {
                        setMakePayment(true)
                      }
                    }}
                  >
                    <Doorman>
                      <DoormanChat
                        className="text-focus-in"
                        animationDelay="2s"
                      >
                        {siteText.pwSuccess}
                      </DoormanChat>
                      <DoormanChat
                        className="text-focus-in"
                        animationDelay="4s"
                      >
                        {pwSuccess.description}
                      </DoormanChat>
                      <DoormanChat
                        className="text-focus-in"
                        animationDelay="6s"
                      >
                        {new Date(pwSuccess.time).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                        {` on `}
                        {new Date(pwSuccess.time).toLocaleString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                        .
                      </DoormanChat>
                      <DoormanChat
                        className="text-focus-in"
                        animationDelay="12s"
                        animationDuration="3s"
                        tip
                      >
                        {`${siteText.pwSuccessAskForTip}`}
                      </DoormanChat>

                      <TipContainer
                        className="text-focus-in"
                        animationDelay="8s"
                      >
                        {(() => {
                          if (pwSuccess.amount) {
                            return (
                              <TipDoorman type="submit">
                                {siteText.tipYourDoorman}
                              </TipDoorman>
                            )
                          }

                          return (
                            <div>
                              <DoormanChat className="text-focus-in">
                                {siteText.missingDoorman}
                              </DoormanChat>
                            </div>
                          )
                        })()}
                      </TipContainer>
                    </Doorman>
                  </FormWrapper>
                </DoormanWrapper>
              )
            }

            if (!paymentSuccess && makePayment && pwSuccess.amount) {
              return (
                <StandardWrapper className="text-focus-in">
                  <Stripe
                    setPaymentSuccess={setPaymentSuccess}
                    setPaymentError={setPaymentError}
                    pwSuccess={pwSuccess}
                    zoomLink={zoomLink}
                    zoomError={zoomError}
                  />
                </StandardWrapper>
              )
            }

            if (paymentSuccess && !zoomLink && !zoomError) {
              return (
                <DoormanWrapper className="text-focus-in" animationDelay="0s">
                  <Doorman>
                    <DoormanChat className="text-focus-in" animationDelay="0s">
                      {siteText.zoomGet}
                    </DoormanChat>
                  </Doorman>
                </DoormanWrapper>
              )
            }

            if (paymentSuccess && zoomError) {
              return (
                <DoormanWrapper className="text-focus-in" animationDelay="0s">
                  <Doorman>
                    <DoormanChat className="text-focus-in" animationDelay="0s">
                      {siteText.zoomFail}
                    </DoormanChat>
                  </Doorman>
                </DoormanWrapper>
              )
            }

            if (paymentSuccess && zoomLink) {
              return (
                <DoormanWrapper className="text-focus-in" animationDelay="1s">
                  <FormWrapper>
                    <Doorman>
                      <DoormanChat
                        className="text-focus-in"
                        animationDelay="2s"
                      >
                        {siteText.paymentSuccess}
                      </DoormanChat>

                      <div>
                        <DoormanChat
                          className="text-focus-in"
                          animationDelay="4s"
                        >
                          {siteText.zoomSuccess}
                        </DoormanChat>
                        <DoormanChat
                          className="text-focus-in"
                          animationDelay="6s"
                        >
                          <a href={zoomLink} target="_blank">
                            {zoomLink}
                          </a>
                        </DoormanChat>
                        <DoormanChat
                          className="text-focus-in"
                          animationDelay="8s"
                        >
                          {siteText.zoomTime}
                        </DoormanChat>
                        <DoormanChat
                          className="text-focus-in"
                          animationDelay="10s"
                        >
                          {new Date(pwSuccess.time).toLocaleString("en-US")}
                        </DoormanChat>
                      </div>
                    </Doorman>
                  </FormWrapper>
                </DoormanWrapper>
              )
            }
          }
          return null
        })()}
      </GridBlockFive>

      <GridBlockSix />
    </MainWrapper>
  )
}
export default BlindTiger
